<template>
  <div id="app">
    <v-app>
      <app-nav></app-nav>

      <router-view style="width: 100%; margin-top: 0em"></router-view>

      <section class="footer" height="" dark>
        <v-card
          flat
          :img="footerImage"
          height="10vh"
          class="d-flex align-start justify-space-between"
        >
          <div class="d-flex mx-6 justify-end">
            <div>
              Tejasvi Koya
              <p>Copyright © teju.rocks 2021.</p>
            </div>
            <div>
              <div class="d-flex justify-space-around mx-6">
                <a
                  style="text-decoration: none"
                  target="_blank"
                  href="https://www.twitter.com"
                  ><v-icon>mdi-twitter</v-icon></a
                >
                <a
                  style="text-decoration: none"
                  target="_blank"
                  href="https://www.facebook.com"
                >
                  <v-icon>mdi-facebook</v-icon></a
                >
              </div>
            </div>
          </div>
        </v-card>
      </section>
    </v-app>
  </div>
</template>

<script>
import AppNav from "./components/Navigation/Nav.vue";

export default {
  name: "app",
  components: {
    AppNav,
  },
  data() {
    return {
      fab: false,
      footerImage: require("./assets/img/footer.svg"),
    };
  },
  computed: {},
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 500;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  created() {
    this.axios.interceptors.request.use((config) => {
      this.isWaiting = true;
      if (
        !config.url.includes("hereapi") &&
        this.$store.getters.isAuthenticated
      ) {
        var user = this.$store.getters.getUser;
        config.headers["x-access-token"] = user.token;
      }
      return config;
    });
    this.axios.interceptors.response.use(
      (response) => {
        this.isWaiting = false;
        return response;
      },
      (error) => {
        this.isWaiting = false;
        const { status } = error.response;
        if (status === 401) {
          this.$store.dispatch("logout");
          this.$router.push({ name: "app" });
        }
        return Promise.reject(error);
      }
    );
  },
};
</script>

<style>
#app {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.footer {
  margin-top: auto;
}
</style>
