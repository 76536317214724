<template>
  <div v-scroll="onScroll">
    <v-app-bar :color="appBarColor" :dark="darkToolBar" flat dense app>
      <v-app-bar-nav-icon @click="home">
        <template v-slot:default>
          <div style="padding: 2em">
            <v-img
              :src="navOptions.brandImage"
              max-width="25em"
              max-height="3em"
              contain
            ></v-img>
          </div>
        </template>
      </v-app-bar-nav-icon>
      <v-toolbar-title @click="home" class="header-title" color="#e12404">
        {{ $route.meta.title || "Teju rocks" }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text @click="$router.push({ name: 'about' })">About me</v-btn>
      <v-btn text @click="$router.push({ name: 'musings' })">My Musings</v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  components: {},
  name: "MainViewer",
  data() {
    return {
      dialog: false,
      elevate: false,
    };
  },
  computed: {
    appBarColor() {
      return this.elevate ? "#014b60" : "transparent";
    },
    darkToolBar() {
      return this.elevate;
    },

    navOptions() {
      let menuOptionsRightComputed = [];

      let navBaseOptions = {
        brandImage: require("../../assets/img/background.jpg"),
        brandImageAltText: "brand-image",
        ariaLabelMainNav: "Main Navigation",
        tooltipAnimationType: "shift-away",
        menuOptionsRight: menuOptionsRightComputed,
      };
      return navBaseOptions;
    },
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.elevate = top > 50;
    },
    home() {
      this.$router.push({ name: "app" });
    },
    onNavItemClicked(navItem) {
      if (navItem.text === "Sign Out") {
        this.$store.dispatch("logout");
      }
      if (navItem.path) this.$router.push(navItem.path);
    },
    openUserNav() {
      this.$root.$emit("openUserNav");
      this.elevate = false;
    },
  },
  mounted() {
    this.$root.$on("userNavClosed", () => {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || 0;
      this.elevate = top > 50;
      // this.elevate = true;
    });
  },
};
</script>

<style>
.vnb .vnb__popup {
  box-shadow: 0 1vmin 7vmin rgba(0, 0, 0, 0.2);
}

.v-application ol,
.v-application ul {
  padding: 0px !important;
}

.vnb .vnb__popup__bottom__menu-options__option__link {
  color: #2c3e50 !important;
  font-size: 24px;
  font-weight: bold;
}

.vnb__popup__bottom__sub-menu-options__option__link {
  text-align: left;
}

.nb__popup__bottom__menu-options li {
  font-size: 10px;
}

.vnb .vnb__menu-options__option__link {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  color: #1d4f91;
  font-size: 1.1em;
}

.vnb .vnb__menu-options__option__link:hover {
  color: white;
  background-color: black;
}

/* Style left drop down menu */
.vnb .vnb__menu-options--left .vnb__menu-options__option__link:hover {
  color: #1d4f91;
  background-color: transparent;
}

/* Disable outline when clicking navigation links */
a:active,
a:focus {
  outline: 0;
  border: none;
}

.vnb .nav-logout {
  background: white;
  color: black;
  /* font-weight: bold; */
}

.vnb .nav-logout:hover {
  background: white;
  color: #034d19;
  /* font-weight: bold; */
  cursor: pointer;
}

.nav-restart {
  background-color: grey;
  color: purple;
}

.vnb .vnb__popup {
  border-radius: 5px;
}

.vnb .vnb__popup__bottom__menu-options__option__link {
  color: #595959;
}
.header-title {
  margin: 4vmin 0 4vmin 0;
  font-weight: 500;
  font-size: 5vmin;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
</style>
