<template>
  <div>
    <v-row class="" no-gutters>
      <v-col cols="12" sm="6">
        <v-card
          flat
          :img="homeImage2"
          height="80vh"
          class="d-flex align-center"
        >
          <div class="d-flex flex-column mx-6">
            <p class="text-h4 d-flex justify-start">Hi there!</p>
            <p class="text-h6">
              I am Teju and I like to draw my thoughts about this beautiful
              world
            </p>
            <div class="align-center">
              <v-btn
                color="#024b60"
                dark
                @click="$router.push({ name: 'musings' })"
                >Explore my world</v-btn
              >
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card
          flat
          :img="homeImage1"
          height="80vh"
          class="d-flex align-center justify-center"
        >
          <v-img :src="girl" width="5vw"></v-img>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},
  name: "Homepage",
  data() {
    return {
      homeImage1: require("../assets/img/background.svg"),
      homeImage2: require("../assets/img/musings.svg"),
      girl: require("../assets/img/girl-reading-book.gif"),
    };
  },
};
</script>

<style scoped>
</style>
