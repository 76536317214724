let appConfig = null;
const getRuntimeConfig = async () => {

    try {
        const runtimeConfig = await fetch('musings_content.json');
        return await runtimeConfig.json()
    } catch (ex) {
        window.console.log("Error reading config")
    }

}
export default class AppConfig {
    async load() {
        if (!appConfig) {
            appConfig = await getRuntimeConfig()
        }
        return appConfig

    }
    getConfig() {
        return appConfig;
    }
}