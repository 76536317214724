<template>
  <div>
    <v-row class="" no-gutters>
      <v-col cols="12" sm="6">
        <v-card
          flat
          :img="homeImage2"
          height="80vh"
          class="d-flex align-center"
        >
          <div class="d-flex flex-column mx-6">
            <p class="text-h6">
              My name is Tejasvi and I love art. I have drawn lots of pictures.
              This website will let you see them all. I hope you really like it!
              I also have a sister. Her name is Tanmayi. She likes the color
              <strong style="color: pink">pink</strong>. She also likes to play
              with me In this website, I will show some of her pictures
            </p>
            <div class="align-center">
              <v-btn
                color="#024b60"
                dark
                @click="$router.push({ name: 'musings' })"
                >Explore my world</v-btn
              >
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card
          flat
          :img="homeImage1"
          height="80vh"
          class="d-flex align-center justify-center"
        >
          <v-img :src="girl" width="5vw"></v-img>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  components: {},
  name: "About",
  data() {
    return {
      homeImage1: require("../../assets/img/background.svg"),
      homeImage2: require("../../assets/img/musings.svg"),
    };
  },

  methods: {
    openPage(name) {
      this.$router.push({ name: name });
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 500;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    isPageCompleted() {
      /* eslint-disable no-console */
    },
    scrollToSection(name) {
      var element = this.$refs[name];
      var top = element.offsetTop;
      window.scrollTo(0, top - 100);
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.fullpage {
  /* height: 100vh; */
  width: 100%;
}
.header-title {
  margin: 4vmin 0 4vmin 0;
  font-weight: 100;
  font-size: 10vmin;
  font-style: bottom;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #b51f1f;
}
.header1 {
  /* background-image: url("../assets/img/background3.jpeg"); */
  background-image: white !important;
  background-position: bottom;
  height: 80vh;
}
.spaced {
  padding: 1em 0 0.25em 0;
}
</style>
