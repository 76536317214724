<template>
  <div class="header">
    <v-container>
      <v-dialog v-model="dialog" width="600px">
        <v-card v-if="selected">
          <v-card-title>
            <span class="text-h5">{{ selected.title }}</span>
          </v-card-title>
          <v-card-text>{{ selected.description }}</v-card-text>
          <v-img
            contain
            v-if="selected.image"
            :src="`https://storage.googleapis.com/www.teju.rocks/data/${selected.image}`"
            height="70vh"
          >
          </v-img>
          <pdf
            v-if="selected.pdf"
            :src="`https://storage.googleapis.com/www.teju.rocks/data/${selected.pdf}`"
          ></pdf>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="minimize">
              <v-icon>mdi-thumb-down</v-icon>
            </v-btn>
            <v-btn color="green darken-1" text @click="minimize">
              <v-icon>mdi-thumb-up</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-iterator
        :items="musings"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :sort-desc="true"
        :search="search"
        hide-default-footer
        class="my-6"
        style="margin-top: 5em !important"
      >
        <template v-slot:header>
          <v-toolbar flat dense color="transparent" class="my-4">
            <v-spacer></v-spacer>
            <v-text-field
              outlined
              max-width="50%"
              dense
              v-model="search"
              clearable
              flat
              solo
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Search by name"
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.name"
              cols="12"
              xs="12"
              :sm="colCount"
              :md="colCount"
              :lg="colCount"
            >
              <v-card
                elevation="20"
                class="mx-2 my-2"
                @click="maximize(item)"
                height=""
              >
                <v-img
                  position="center"
                  height="30vh"
                  class="poi-card"
                  v-if="item.image"
                  :src="`https://storage.googleapis.com/www.teju.rocks/data/${item.image}`"
                ></v-img>
                <pdf
                  v-if="item.pdf"
                  :src="`https://storage.googleapis.com/www.teju.rocks/data/${item.pdf}`"
                ></pdf>
                <v-row>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="poi-title"
                        ><strong>{{ item.title }}</strong></v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-card-text>{{ slide.description }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer>
          <v-row class="my-6" align="center" justify="center">
            <span class="grey--text">Items per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  color="primary"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <span class="mr-4 grey--text">
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn
              x-small
              dark
              color="#014b60"
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn x-small dark color="#014b60" class="ml-1" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import AppConfig from "../../../app_configuration";

export default {
  name: "Musings",
  components: { pdf },
  data() {
    return {
      dialog: false,
      musings: [],
      slide: 0,
      itemsPerPageArray: [12, 20, 30],
      search: "",
      filter: {},
      page: 1,
      itemsPerPage: 12,
      keys: ["title"],
      selected: {},
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.musings.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
    colCount() {
      return 12 / 6;
    },
  },
  methods: {
    maximize(item) {
      this.selected = item;
      this.dialog = true;
    },
    minimize() {
      this.selected = {};
      this.dialog = false;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
  created() {
    var appConfig = new AppConfig().getConfig();

    this.musings =
      appConfig && appConfig.data
        ? appConfig.data
        : [
            {
              title: "Ballet World",
              image: "ballet.jpeg",
              content: "Dancing away",
            },
            {
              title: "Island",
              image: "Island.jpeg",
              content: "Funny Story",
            },
            {
              title: "Walk in the park",
              image: "parknplay.png",
              content: "Amused by the beauty",
            },
            {
              title: "Bunny",
              image: "rabbit.jpeg",
              content: "Giant hopper",
            },
            {
              title: "Flower friends",
              image: "spring_1.png",
              content: "Flowers all around",
            },
            {
              title: "",
              image: "spring_2.png",
              content: "Blooming",
            },
            {
              title: "Random Thoughts",
              image: "Modern.jpeg",
              content: "Life is bright",
            },
            {
              title: "Milk and Cookie",
              image: "milk_cookie.png",
              content: "Good Morning",
            },
          ];
  },
};
</script>
<style scoped>
.header {
  background-image: url("../../assets/img/background.svg");
}
</style>