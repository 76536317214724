<template>
  <div>
    <div style="position: fixed; margin-top: -5em">
      <div class="bgOverlay" />
      <transition name="fade"> </transition>
    </div>
    <section class="fullpage mb-10">
      <v-card
        class="d-flex justify-center align-center"
        color="transparent"
        flat
        tile
        height="400"
      >
        <v-img :src="wip"></v-img>
      </v-card>
    </section>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  props: {},
  data() {
    return {
      wip: require("../../assets/img/work_in_progress.jpeg"),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fullpage {
  background: linear-gradient(180deg, #9f408b, #334663);
}
</style>
