import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from '@/plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import dayjs from 'dayjs'
import AppConfig from '../app_configuration';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(dayjs)

Vue.filter('truncate', function (text, stop, clamp) {
	return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})



new AppConfig().load().then((cfg) => {
	Vue.prototype.$config = cfg;
	new Vue({
		router,
		vuetify,
		render: (h) => h(App),
	}).$mount('#app');
});

