import Vue from 'vue';
import VueRouter from 'vue-router';

import Homepage from '@/components/Homepage.vue';
import NotFound from '@/components/pages/NotFound.vue';
import About from '@/components/pages/About.vue';
import Musings from '@/components/pages/Musings.vue';



Vue.use(VueRouter);

// Please import your components and define your route
const router = new VueRouter({
	routes: [
		{
			path: '/',
			name: 'app',
			component: Homepage,
			meta: { home: true, public: true },
		},
		{
			path: '/about',
			name: 'about',
			component: About,
			meta: { public: true },
		},
		{
			path: '/musings',
			name: 'musings',
			component: Musings,
			meta: { public: true },
		},
		{ path: '*', name: '*', component: NotFound, meta: { back: true, public: true } },
	],
	mode: 'hash',
});

export default router;
